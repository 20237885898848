import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "../pages/Home";
import AdminLogin from "../pages/AdminLogin";
import AdminRoute from "../components/AdminRoute";
import PageNotFound from "../pages/PageNotFound";

const AppRoutes: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<HomePage />} />

                <Route path='' element={<AdminRoute />}>
                    <Route path='/admin/login' element={<AdminLogin />} />
                </Route>

                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes
