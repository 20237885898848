import React from 'react';

const AdminLoginForm: React.FC = () => {
    return (
        <>
            <div>
                { /* login id */ }
                <p>
                    <input
                        type="text"
                        placeholder="ログインID"
                    />
                </p>

                { /* password */ }
                <p>
                    <input
                        type="password"
                        placeholder="パスワード"
                    />
                </p>
            </div>
            <div className="buttons">
                <button>ログイン</button>
            </div>
        </>
    )
}

export default AdminLoginForm
