import React, { useState } from "react";
import AdminLoginForm from "../components/AdminLoginForm";
import AdminSetupForm from "../components/AdminSetupForm";

const AdminLogin: React.FC = () => {
    const [isAdminExists, setIsAdminExists] = useState<boolean>(true);

    return (
        <>
            <header>
                <h1>Admin's Login Page</h1>
            </header>
            <main>
                { isAdminExists && <AdminLoginForm /> }
                { !isAdminExists && <AdminSetupForm /> }
            </main>
        </>
    )
}

export default AdminLogin
