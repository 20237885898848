import React from "react";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";

const AdminRoute: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Admin</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <Outlet />
        </>
    )
}

export default AdminRoute
