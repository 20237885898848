import React from 'react';

const AdminSetupForm: React.FC = () => {
    return (
        <>
            <div>
                
            </div>
            <div className='buttons'>
                <button>SAVE</button>
            </div>
        </>
    )
}

export default AdminSetupForm
